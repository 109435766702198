import React from "react";

import { HTMLProps } from "react";

import Link from "@Js/Component/Link/Link";

import clsx from "clsx";

import parse from "html-react-parser";

import classNames from "@Css/Component/PostGrid/PostGrid.module.scss";

type PostEdge = {
    node: {
        acf: {
            read: string;
        };
        categories: {
            name: string;
            wordpress_id: number;
        }[];
        date: string;
        excerpt: string;
        slug: string;
        title: string;
        wordpress_id: number;
    };
};

export type PostGridProps = Readonly<HTMLProps<HTMLElement> & {
    from: number;
    postEdges: PostEdge[];
    to: number;
}>;

const PostGrid = (props: PostGridProps) => {
    const {
        className,
        from,
        postEdges,
        to,
        ...restProps
    } = props;

    return (
        <section { ...restProps } className={ clsx(classNames.postGrid, className) }>
            <ul className={ classNames.list }>
                { postEdges.slice(from, to).map((edge) => (
                    <li className={ classNames.listItem } key={ edge.node.wordpress_id }>
                        <section className={ classNames.post }>
                            <div className={ classNames.categories }>
                                { edge.node.categories.map((category) => (
                                    <div className={ classNames.category } key={ category.wordpress_id }>{ category.name }</div>
                                )) }
                            </div>
                            <h1 className={ classNames.title }>{ parse(edge.node.title) }</h1>
                            <time className={ classNames.dateTop }>{ edge.node.date }</time>
                            <div className={ classNames.line } />
                            <div className={ classNames.excerpt }>{ parse(edge.node.excerpt) }</div>
                            <div className={ classNames.info }>
                                <time className={ classNames.dateBottom }>{ edge.node.date }</time>
                                <div className={ classNames.read }>Read { edge.node.acf.read } min.</div>
                            </div>
                            <Link
                                className={ classNames.button }
                                to={ "/" + edge.node.slug }
                            >
                                Lees meer
                            </Link>
                        </section>
                    </li>
                )) }
            </ul>
        </section>
    );
};

export default PostGrid;
