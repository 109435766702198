import React from "react";

import { Fragment } from "react";

import { Color } from "three";
import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer";
import { CSS2DRenderer } from "three/examples/jsm/renderers/CSS2DRenderer";
import { DirectionalLight } from "three";
import { Fog } from "three";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import { Group } from "three";
import { Mesh } from "three";
import { MeshBasicMaterial } from "three";
import { OBJLoader2 } from "three/examples/jsm/loaders/OBJLoader2";
import { PerspectiveCamera } from "three";
import { Scene } from "three";
import { SphereGeometry } from "three";
import { WebGLRenderer } from "three";


import { createRef } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";

import Link from "@Js/Component/Link/Link";

import MapGltf from "@Js/Gltf/Map.gltf";
import PinObj from "@Js/Obj/Pin.obj";

import classNames from "@Css/Component/Map/Map.module.scss";

const Map = () => {
    const ref = useRef<HTMLDivElement>(null);

    const [pins] = useState([
        {
            hover: false,
            id: 0,
            name: "Emmen",
            position: [-0.642, 0.177, 0.307],
            ref: createRef<HTMLDivElement>(),
            url: "#"
        },
        {
            hover: false,
            id: 0,
            name: "Hoogeveen",
            position: [0.348, 0.178, -0.451],
            ref: createRef<HTMLDivElement>(),
            url: "#"
        },
        {
            hover: false,
            id: 0,
            name: "Coevorden",
            position: [0.880, 0.180, 0.014],
            ref: createRef<HTMLDivElement>(),
            url: "#"
        },
        {
            hover: false,
            id: 0,
            name: "Hardenberg",
            position: [0.5, 0.09, 0.45],
            ref: createRef<HTMLDivElement>(),
            url: "#"
        }
    ]);

    useEffect(() => {
        if (typeof window == "undefined") {
            return;
        }

        let animationFrameId: number;
        let previousTimestamp: number;

        const scene = new Scene();
        {
            scene.fog = new Fog(0x000000, 0.1, 5);
        }

        const camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 10);
        {
            camera.position.x = 2;
            camera.position.y = 1;

            camera.lookAt(scene.position);
        }

        const renderer = new WebGLRenderer();
        {
            if (ref.current) {
                renderer.setSize(ref.current.clientWidth, ref.current.clientHeight);
            }
        }

        const renderer2D = new CSS2DRenderer();
        {
            renderer2D.domElement.style.position = "absolute";
            renderer2D.domElement.style.left = "0";
            renderer2D.domElement.style.top = "0";

            if (ref.current) {
                renderer2D.setSize(ref.current.clientWidth, ref.current.clientHeight);
            }
        }

        const directionalLight = new DirectionalLight(new Color(0x40606f), 1);
        {
            directionalLight.castShadow = true;
            directionalLight.shadow.mapSize.height = 4096;
            directionalLight.shadow.mapSize.width = 4096;
            directionalLight.position.x = 5;
            directionalLight.position.y = 10;
            directionalLight.position.z = 5;

            scene.add(directionalLight);
        }

        let group: Group;

        const gltfLoader = new GLTFLoader();
        {
            gltfLoader.parse(MapGltf, "", (gltf: GLTF) => {
                group = gltf.scene;

                gltf.scene.traverse((object) => {
                    if (object.type == "Mesh") {
                        object.castShadow = true;
                        object.receiveShadow = true;
                    }
                });

                const objLoader = new OBJLoader2();
                {
                    const object = objLoader.parse(PinObj);
                    {
                        const mesh = object.children[0] as Mesh;
                        {
                            mesh.material = new MeshBasicMaterial({color: 0xffffff});
                        }

                        pins.forEach((pin) => {
                            const sphereGeometry = new SphereGeometry(0.15, 10, 10);
                            const sphereMaterial = new MeshBasicMaterial({ color: 0xffffff });
                            const sphere = new Mesh(sphereGeometry, sphereMaterial);
                            {
                                sphere.name = "Sphere";
                                sphere.position.fromArray(pin.position);
                                sphere.material.opacity = 0;
                                sphere.material.transparent = true;
                                sphere.scale.set(1.5, 1.5, 1.5);

                                pin.id = sphere.id;
                            }

                            const clone = object.clone();
                            {
                                clone.name = "Pin";
                                clone.position.fromArray(pin.position);
                                clone.scale.set(1.5, 1.5, 1.5);
                            }

                            const label = new CSS2DObject( pin.ref.current as HTMLElement );
                            {
                                label.position.set(0, 0.20, 0);
                            }

                            clone.add(label);

                            group.add(sphere);
                            group.add(clone);
                        });
                    }
                }

                scene.add(group);
            });
        }

        const onWindowResize = () => {
            if (ref.current) {
                camera.aspect = ref.current.clientWidth / ref.current.clientHeight;
                camera.updateProjectionMatrix();

                renderer.setSize(ref.current.clientWidth, ref.current.clientHeight);
                renderer2D.setSize(ref.current.clientWidth, ref.current.clientHeight);
            }
        };

        const onAnimationFrame = (timestamp: number = performance.now()) => {
            const delta = previousTimestamp ? (timestamp - previousTimestamp) / 1000 : 0;

            previousTimestamp = timestamp;
            animationFrameId = requestAnimationFrame(onAnimationFrame);

            if (group) {
                group.rotation.y += 0.10 * delta;
            }

            renderer.render(scene, camera);
            renderer2D.render(scene, camera);
        };

        onAnimationFrame();
        onWindowResize();

        window.addEventListener("resize", onWindowResize);

        if (ref.current) {
            ref.current.appendChild(renderer.domElement);
            ref.current.appendChild(renderer2D.domElement)
        }

        return () => {
            window.removeEventListener("resize", onWindowResize);

            cancelAnimationFrame(animationFrameId);

            if (ref.current) {
                ref.current.removeChild(renderer.domElement);
                ref.current.removeChild(renderer2D.domElement);
            }
        };
    }, []);

    return (
        <Fragment>
            { pins.map((pin, index) => (
                <div className={ classNames.tooltip } key={ index } ref={ pin.ref }>
                    <Link to={ pin.url }>{ pin.name }</Link>
                </div>
            )) }
            <div className={ classNames.wrapper } ref={ ref } />
        </Fragment>
    );
};

export default Map;
