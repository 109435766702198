import React from "react";

import { FluidObject } from "gatsby-image";

import { Fragment } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import parse from "html-react-parser";

import moment from "moment";

import { config } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import EventList from "@Js/Component/EventList/EventList";
import Map from "@Js/Component/Map/Map";
import PostGrid from "@Js/Component/PostGrid/PostGrid";
import PostImageGrid from "@Js/Component/PostImageGrid/PostImageGrid";

import classNames from "@Css/Component/Page/Index.module.scss";

config.autoAddCss = false;

type PostEdge = {
    node: {
        acf: {
            read: string;
        };
        categories: {
            name: string;
            wordpress_id: number;
        }[];
        date: string;
        excerpt: string;
        featured_media?: {
            localFile: {
                childImageSharp: {
                    fluid: FluidObject;
                };
            };
        };
        slug: string;
        title: string;
        wordpress_id: number;
    };
};

type EventEdge = {
    node: {
        acf: {
            event_location: string;
            event_datetime: string;
            event_datetime_formatted: string;
        };
        excerpt: string;
        slug: string,
        title: string;
        wordpress_id: string;
    };
};

type Options = {
    options: {
        homepage_intro: string;
    };
};

const IndexPage = () => {
    const result = useStaticQuery(graphql`
        query {
            allWordpressPost(sort: { fields: date, order: DESC }) {
                edges {
                    node {
                        acf {
                            read
                        }
                        categories {
                            name
                            wordpress_id
                        }
                        date(formatString: "D MMM YYYY", locale: "NL-nl")
                        excerpt
                        featured_media {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 1920) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                        slug
                        title
                        wordpress_id
                    }
                }
            }
            allWordpressWpEvent(sort: { fields: acf___event_datetime, order: ASC }) {
                edges {
                    node {
                        acf {
                            event_datetime
                            event_datetime_formatted: event_datetime(formatString: "D MMM YYYY \\o\\m HH:mm", locale: "NL-nl")
                            event_location
                        }
                        excerpt
                        slug
                        title
                        wordpress_id
                    }
                }
            }
            wordpressAcfOptions {
                options {
                    homepage_intro
                }
            }
        }
    `);

    const postEdges = result.allWordpressPost.edges as PostEdge[];
    const eventEdges = result.allWordpressWpEvent.edges as EventEdge[];
    const options = result.wordpressAcfOptions as Options;

    const postsFrom = 8;
    const postsTo = 11;

    const onButtonClick = () => {
        window.scrollTo({
            behavior: "smooth",
            top: window.innerHeight
        });
    };

    return (
        <Fragment>
            <section className={ classNames.map }>
                <header className={ classNames.header }>
                    <div className={ classNames.wrapper }>
                        <h1 className={ classNames.title }>De regio van technisch vakmanschap voor de maakindustrie</h1>
                        <h2 className={ classNames.subtitle }>In Dutch TechZone werken we samen, inspireren we elkaar, delen we kennis en creëren we het ultieme ondernemersklimaat om door te groeien.</h2>
                    </div>
                    <button className={ classNames.button } onClick={ onButtonClick } type="button">
                        <FontAwesomeIcon className={ classNames.buttonIcon } icon={ faChevronDown } />
                    </button>
                </header>
                <Map />
            </section>
            <main>
                { options.options.homepage_intro && (
                    <div className={ classNames.intro }>{ parse(options.options.homepage_intro) }</div>
                ) }
                <EventList eventEdges={ eventEdges.filter((eventEdge) => {
                    return moment(eventEdge.node.acf.event_datetime).isAfter();
                }).slice(0, 6) } showMore />
                <PostImageGrid postEdges={ postEdges.slice(0, 8) } />
                <PostGrid className={ classNames.postGrid } from={ postsFrom } postEdges={ postEdges } to={ postsTo } />
            </main>
        </Fragment>
    );
};

export default IndexPage;
