import Img from "gatsby-image";
import React from "react";

import { FluidObject } from "gatsby-image";
import { HTMLProps } from "react";

import Link from "@Js/Component/Link/Link";

import clsx from "clsx";

import parse from "html-react-parser";

import classNames from "@Css/Component/PostImageGrid/PostImageGrid.module.scss";

type PostEdge = {
    node: {
        acf: {
            read: string;
        };
        categories: {
            name: string;
            wordpress_id: number;
        }[];
        date: string;
        excerpt: string;
        featured_media?: {
            localFile: {
                childImageSharp: {
                    fluid: FluidObject;
                };
            };
        };
        slug: string;
        title: string;
        wordpress_id: number;
    };
};

export type PostImageGridProps = Readonly<HTMLProps<HTMLElement> & {
    postEdges: PostEdge[];
}>;

const PostImageGrid = (props: PostImageGridProps) => {
    const {
        className,
        postEdges,
        ...restProps
    } = props;

    return (
        <section { ...restProps } className={ clsx(classNames.postImageGrid, className) }>
            <ul className={ classNames.list }>
                { postEdges.map((edge) => (
                    edge &&
                    <li className={ classNames.listItem } key={ edge.node.wordpress_id }>
                        <section className={ classNames.post }>
                            <Link
                                className={ classNames.link }
                                to={ "/" + edge.node.slug }
                            >
                                { edge.node.featured_media &&
                                <Img
                                    className={ classNames.image }
                                    fluid={ edge.node.featured_media.localFile.childImageSharp.fluid }
                                />
                                }
                                <div className={ classNames.categories }>
                                    { edge.node.categories.map((category) => (
                                        <div className={ classNames.category } key={ category.wordpress_id }>{ category.name }</div>
                                    )) }
                                </div>
                                <div className={ classNames.box }>
                                    <h1 className={ classNames.title }>{ parse(edge.node.title) }</h1>
                                    <div className={ classNames.info }>
                                        <time className={ classNames.date }>{ edge.node.date }</time>
                                        <span className={ classNames.read }>Read { edge.node.acf.read } min.</span>
                                    </div>
                                </div>
                            </Link>
                        </section>
                    </li>
                )) }
            </ul>
        </section>
    );
};

export default PostImageGrid;
